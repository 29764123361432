import React from "react";
import logo from "../src/Assets/logoDark.svg";

const Navbar = () => {
  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration={400}
      fs-scrolldisable-element="smart-nav"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar12_component w-nav"
    >
      <div className="navbar12_container">
        <a
          href="/"
          aria-current="page"
          className="navbar12_logo-link w-nav-brand w--current"
        >
          <img src={logo} loading="lazy" alt="" className="navbar12_logo" />
        </a>
        <nav
          role="navigation"
          className="navbar12_menu is-page-height-tablet w-nav-menu"
        >
          <a
            href="/"
            aria-current="page"
            className="navbar12_link w-nav-link w--current"
          >
            Home
          </a>
          <a href="/about" className="navbar12_link w-nav-link">
            About Us
          </a>
          <a href="/industries" className="navbar12_link w-nav-link">
            Industries
          </a>

          <div
            data-hover="true"
            data-delay={200}
            className="navbar12_menu-dropdown w-dropdown"
          >
            <div className="navbar12_dropdown-toggle w-dropdown-toggle">
              <div className="dropdown-icon w-embed">
                <svg
                  width=" 100%"
                  height=" 100%"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                    fill="currentColor"
                  />
                </svg>
              </div>

              <a className="navbar12_link w-nav-link">More</a>
            </div>
            <nav className="navbar12_dropdown-list w-dropdown-list">
              <div className="navbar12_dropdown-content">
                <div className="navbar12_dropdown-link-list">
                  <a
                    href="faq"
                    className="navbar12_dropdown-link w-inline-block"
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">FAQ</div>
                    </div>
                  </a>
                  <a
                    href="privacy"
                    className="navbar12_dropdown-link w-inline-block"
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">Privacy Policy</div>
                    </div>
                  </a>
                  <a
                    href="termsandconditions"
                    className="navbar12_dropdown-link w-inline-block"
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">
                        Terms and Conditions
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div
            data-hover="true"
            data-delay={200}
            className="navbar12_menu-dropdown w-dropdown"
          >
            <div className="navbar12_dropdown-toggle w-dropdown-toggle">
              <div className="dropdown-icon w-embed">
                <svg
                  width=" 100%"
                  height=" 100%"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                    fill="currentColor"
                  />
                </svg>
              </div>

              <a className="navbar12_link w-nav-link">Language</a>
            </div>
            <nav className="navbar12_dropdown-list w-dropdown-list">
              <div className="navbar12_dropdown-content">
                <div className="navbar12_dropdown-link-list">
                  <a
                    href="#googtrans(en|en)"
                    data-lang="en"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">English</div>
                    </div>
                  </a>
                  <a
                    href="#googtrans(en|zh-CN)"
                    data-lang="zh-CN"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">Chinese</div>
                    </div>
                  </a>
                  <a
                    href="#googtrans(en|ar)"
                    data-lang="ar"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">Arabic</div>
                    </div>
                  </a>
                  <a
                    href="#googtrans(en|fr)"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">Hindi</div>
                    </div>
                  </a>
                  <a
                    href="#googtrans(en|es)"
                    data-lang="es"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">Spanish</div>
                    </div>
                  </a>
                  <a
                    href="#googtrans(en|fr)"
                    data-lang="fr"
                    className="navbar12_dropdown-link w-inline-block"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    }}
                  >
                    <div className="navbar12_text-wrapper">
                      <div className="text-weight-semibold">French</div>
                    </div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div className="navbar12_menu-buttons">
            <a
              href="/contact"
              className="button is-secondary is-small w-button"
            >
              Contact Us
            </a>
          </div>
        </nav>
        <div className="navbar12_menu-button w-nav-button">
          <div className="menu-icon1">
            <div className="menu-icon1_line-top" />
            <div className="menu-icon1_line-middle">
              <div className="menu-icon_line-middle-inner" />
            </div>
            <div className="menu-icon1_line-bottom" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
