import React from "react";
import industries from "../Assets/industries.jpg";
import pharmaceutical from "../Assets/pharmaceutical.jpg";
import food from "../Assets/food.jpg";
import construction from "../Assets/construction.jpg";
import appliances from "../Assets/appliances.jpg";
import agriculture from "../Assets/agriculture.jpg";
import beauty from "../Assets/beauty.jpg";
import { useState, useEffect } from "react";
const Industries = () => {
  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_industries_header">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <h1 className="text-color-white">
                      Connecting Industries Worldwide
                    </h1>
                  </div>
                  <p className="text-size-medium text-color-white">
                    ImportEx serves a wide range of industries, providing
                    seamless import and export solutions tailored to their
                    specific needs.
                  </p>
                  <div className="margin-top margin-medium">
                    <div className="button-group">
                      <a href="about" className="button w-button">
                        Learn More
                      </a>
                      <a
                        href="contact"
                        className="button is-secondary is-alternate w-button"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="industries_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={industries}
              loading="eager"
              alt=""
              className="industries_header_background-image"
            />
          </div>
        </header>
        <section className="section_layout252 ">
          <div className="padding-global-4 ">
            <div className="container-large ">
              <div className="padding-section-large no-padding-bottom">
                <div className="layout252_component">
                  <div className="margin-bottom margin-xxlarge">
                    <div className="w-layout-grid layout252_content">
                      <div className="layout252_content-left">
                        <h3>
                          <strong>Diverse Import and Export Solutions</strong>
                        </h3>
                      </div>
                      <div className="layout252_content-right">
                        <p className="text-size-medium-2 text-color-black">
                          At importEx, we specialize in import and export
                          services across various industries. While we excel in
                          the pharmaceutical, food, appliance, construction,
                          agriculture, and beauty sectors, our extensive network
                          and expertise allow us to offer even more. With our
                          wide-ranging connections and global reach, we're your
                          gateway to a world of international trade
                          opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid layout252_list">
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={pharmaceutical}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Pharmaceutical</h3>
                      </div>
                      <p className="text-color-black">
                        Our import and export services ensure the global
                        availability of critical medications and healthcare
                        essentials, contributing to better health worldwide.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/pharmaceutical"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={food}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Food</h3>
                      </div>
                      <p className="text-color-black">
                        With a focus on culinary diversity, we facilitate the
                        import and export of a wide array of food products,
                        connecting global tastes with quality ingredients.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/food"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={beauty}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Beauty</h3>
                      </div>
                      <p className="text-color-black">
                        Our import and export services streamline the
                        distribution of beauty products, enhancing personal care
                        and aesthetics for individuals across the globe.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/beauty"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid layout252_list list-margin-top">
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={construction}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Construction</h3>
                      </div>
                      <p className="text-color-black">
                        We play a pivotal role in the construction sector by
                        providing a seamless supply chain for construction
                        materials and equipment, supporting the realization of
                        robust infrastructure projects.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/construction"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={agriculture}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Agriculture</h3>
                      </div>
                      <p className="text-color-black">
                        Bridging the gap between agriculture and international
                        markets, we enable the efficient import and export of
                        agricultural products, ensuring a steady flow of
                        high-quality goods.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/agriculture"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="layout252_item">
                      <div className="margin-bottom margin-medium">
                        <div className="layout252_image-wrapper">
                          <img
                            src={appliances}
                            loading="lazy"
                            alt=""
                            className="layout252_image"
                          />
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3 className="heading-style-h5-2">Appliances</h3>
                      </div>
                      <p className="text-color-black">
                        From cutting-edge technology to everyday household
                        necessities, our import and export solutions empower
                        consumers and businesses with a comprehensive range of
                        appliances.
                      </p>
                      <div className="margin-top margin-medium">
                        <div className="button-group">
                          <a
                            href="industry/appliances"
                            className="button-2 is-link is-icon w-inline-block"
                          >
                            <div>Learn More</div>
                            <div className="icon-embed-xxsmall-2 w-embed">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 3L11 8L6 13"
                                  stroke="CurrentColor"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_industries_cta">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large no-padding-bottom">
                <div className="w-layout-grid industries_cta_component">
                  <div className="industries_cta_card">
                    <div className="industries_cta_content">
                      <div className="max-width-large">
                        <div className="margin-bottom margin-xsmall">
                          <h3>Tailored quotes for your industry</h3>
                        </div>
                        <p className="text-size-medium text-color-black">
                          Contact ImportEx for more information on our industry
                          solutions
                        </p>
                      </div>
                    </div>
                    <div className="industries_cta_button-row">
                      <a
                        href="contact"
                        className="w-button-black-hover-secondary button w-button "
                      >
                        Contact
                      </a>
                      <a href="about" className="button is-secondary w-button ">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Industries;
