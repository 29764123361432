import React from "react";
import homeVideo from "../Assets/sydneyHarborVideo.mp4";
import costEffective from "../Assets/costEffective.svg";
import efficientLogistics from "../Assets/efficientLogistics.svg";
import efficientSupply from "../Assets/efficientSupply.svg";
import reliableFreight from "../Assets/reliableFreight.svg";
import reliableSupport from "../Assets/reliableSupport.svg";
import seamlessCustoms from "../Assets/seamlessCustoms.svg";
import streamlinedOperations from "../Assets/streamlinedOperations.svg";

const Home = () => {
  return (
    <div className="page-wrapper">
      <main className="main-wrapper">
        <header className="section_home_hero-header">
          <div className="padding-global">
            <div className="container-large">
              <div className="home_hero-header_content">
                <div className="padding-section-large">
                  <div className="max-width-medium">
                    <div className="margin-bottom margin-small">
                      <h1 className="text-color-white">
                        Import and Export Solutions for Australia
                      </h1>
                    </div>
                    <p className="text-size-medium text-color-white">
                      ImportEx is a leading company specializing in importing
                      and exporting goods to and from Australia. With our
                      extensive experience and global network, we provide
                      efficient and reliable solutions tailored to meet your
                      business needs.
                    </p>
                    <div className="margin-top margin-medium">
                      <div className="button-group">
                        <a href="/about" className="button w-button">
                          Learn More
                        </a>
                        <a
                          href="/contact"
                          className="button is-secondary is-alternate w-button"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_hero-header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <video
              //   className={
              //     selected === index ? "video-slide active" : "video-slide"
              //   }
              className="home_hero-header_background-image"
              src={homeVideo}
              autoPlay
              muted
              loop
              playsInline
              onClick={(e) => {
                e.preventDefault();
                // e.stopPropagation();
              }}
            ></video>
          </div>
        </header>
        <section className="section_home_features-list">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="home_features-list_component">
                  <div className="margin-bottom margin-xxlarge">
                    <div className="text-align-center">
                      <div className="max-width-large">
                        <div className="margin-bottom margin-small">
                          <h2>
                            Streamline Your Imports and Exports with ImportEx
                          </h2>
                        </div>
                        <p className="text-size-medium text-color-black">
                          ImportEx offers a range of services designed to
                          simplify the process of importing and exporting goods.
                          With our expertise and network, we ensure efficient
                          and hassle-free transactions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid home_features-list_list">
                    <div className="home_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={seamlessCustoms}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h4">
                          Seamless Customs Clearance and Documentation
                        </h3>
                      </div>
                      <p className="paragraph-4">
                        Our team handles all customs clearance and documentation
                        requirements, ensuring compliance and smooth operations.
                      </p>
                    </div>
                    <div className="home_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={reliableFreight}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h4">
                          Reliable Freight Forwarding and Shipping Solutions
                        </h3>
                      </div>
                      <p className="paragraph-5">
                        We provide reliable freight forwarding and shipping
                        solutions, delivering your goods on time and in perfect
                        condition.
                      </p>
                    </div>
                    <div className="home_features-list_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={efficientSupply}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h4">
                          Efficient Supply Chain Management and Logistics
                        </h3>
                      </div>
                      <p className="paragraph-6">
                        Our comprehensive supply chain management and logistics
                        services ensure smooth operations and timely delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_home_how-it-works">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid home_how-it-works_component">
                  <div className="home_how-it-works_content-left">
                    <h2 className="heading-6">
                      Simplified Process for Importing and Exporting Goods
                    </h2>
                    <div className="margin-top margin-medium">
                      <div className="button-group">
                        <a
                          href="/about"
                          className="button is-secondary w-button"
                        >
                          Services
                        </a>
                        <a
                          href="/industries"
                          className="button is-link is-icon w-inline-block"
                        >
                          <div className="text-block">Industries</div>
                          <div className="icon-embed-xxsmall w-embed">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 3L11 8L6 13"
                                stroke="CurrentColor"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="home_how-it-works_content-right">
                    <div
                      data-w-id="458ecf78-a041-2a3b-19ad-1b6f763e7f10"
                      className="home_how-it-works_timeline-wrapper"
                    >
                      {/* <div className="home_how-it-works_progress-bar-wrapper">
                        <div className="home_how-it-works_progress-bar" />
                      </div> */}
                      <div
                        data-w-id="458ecf78-a041-2a3b-19ad-1b6f763e7f13"
                        className="w-layout-grid home_how-it-works_timeline-step"
                      >
                        <div className="home_how-it-works_timeline-left">
                          <div className="home_how-it-works_timeline-icon-wrapper">
                            <img
                              src={streamlinedOperations}
                              loading="lazy"
                              alt=""
                              className="icon-1x1-medium"
                            />
                          </div>
                        </div>
                        <div className="home_how-it-works_timeline-right">
                          <div className="home_how-it-works_text-wrapper">
                            <div className="margin-bottom margin-xsmall">
                              <h6 className="heading-2">
                                Streamlined Operations
                              </h6>
                            </div>
                            <p>
                              ImportEx makes the process of importing and
                              exporting goods hassle-free and efficient.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        data-w-id="458ecf78-a041-2a3b-19ad-1b6f763e7f1e"
                        className="w-layout-grid home_how-it-works_timeline-step"
                      >
                        <div className="home_how-it-works_timeline-left">
                          <div className="home_how-it-works_timeline-icon-wrapper">
                            <img
                              src={reliableSupport}
                              loading="lazy"
                              alt=""
                              className="icon-1x1-medium"
                            />
                          </div>
                        </div>
                        <div className="home_how-it-works_timeline-right">
                          <div className="home_how-it-works_text-wrapper">
                            <div className="margin-bottom margin-xsmall">
                              <h6 className="heading-3">Reliable Support</h6>
                            </div>
                            <p>
                              Our team is dedicated to providing reliable
                              support throughout the entire import and export
                              process.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        data-w-id="458ecf78-a041-2a3b-19ad-1b6f763e7f29"
                        className="w-layout-grid home_how-it-works_timeline-step"
                      >
                        <div className="home_how-it-works_timeline-left">
                          <div className="home_how-it-works_timeline-icon-wrapper">
                            <img
                              src={costEffective}
                              loading="lazy"
                              alt=""
                              className="icon-1x1-medium"
                            />
                          </div>
                        </div>
                        <div className="home_how-it-works_timeline-right">
                          <div className="home_how-it-works_text-wrapper">
                            <div className="margin-bottom margin-xsmall">
                              <h6 className="heading-4">
                                Cost-Effective Solutions
                              </h6>
                            </div>
                            <p>
                              ImportEx offers cost-effective solutions for
                              businesses looking to expand their global reach.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        data-w-id="458ecf78-a041-2a3b-19ad-1b6f763e7f34"
                        className="w-layout-grid home_how-it-works_timeline-step"
                      >
                        <div className="home_how-it-works_timeline-left">
                          <div className="home_how-it-works_timeline-icon-wrapper">
                            <img
                              src={efficientLogistics}
                              loading="lazy"
                              alt=""
                              className="icon-1x1-medium"
                            />
                          </div>
                        </div>
                        <div className="home_how-it-works_timeline-right">
                          <div className="home_how-it-works_text-wrapper">
                            <div className="margin-bottom margin-xsmall">
                              <h6 className="heading-5">Efficient Logistics</h6>
                            </div>
                            <p>
                              Our streamlined logistics ensure timely delivery
                              of goods to their intended destinations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_home_cta">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid home_cta_component">
                  <div className="home_cta_card">
                    <div className="home_cta_content">
                      <div className="max-width-large">
                        <div className="margin-bottom margin-xsmall">
                          <h3>ImportEx: Your Trusted Import-Export Partner</h3>
                        </div>
                        <p className="text-size-medium">
                          Unlock new business opportunities with ImportEx's
                          import-export solutions.
                        </p>
                      </div>
                    </div>
                    <div className="home_cta_button-row">
                      <a
                        href="/contact"
                        className="button is-secondary w-button"
                      >
                        Get a Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_home_newsletter">
          <div className="padding-global">
            <div className="container-small">
              <div className="padding-section-large">
                <div className="text-align-center">
                  <div className="margin-bottom margin-small">
                    <h2>Stay Updated with ImportEx News</h2>
                  </div>
                  <p className="text-size-medium text-color-white">
                    Subscribe to our newsletter for the latest updates and
                    promotions.
                  </p>
                  <div className="margin-top margin-medium">
                    <div className="home_newsletter_form-block w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="home_newsletter_form"
                        data-wf-page-id="64f5707f3070b0fe99ebcc5b"
                        data-wf-element-id="458ecf78-a041-2a3b-19ad-1b6f763e7f5d"
                      >
                        <div className="signup-form-wrapper">
                          <input
                            type="email"
                            className="form-input w-input"
                            maxLength={256}
                            name="email-2"
                            data-name="Email 2"
                            placeholder="Enter your email"
                            id="email-2"
                            required=""
                          />
                          <input
                            type="submit"
                            defaultValue="Sign Up"
                            data-wait="Please wait..."
                            className="button w-button"
                          />
                        </div>
                        <div className="text-size-tiny">
                          By clicking Sign Up, you confirm that you agree with
                          our Terms and Conditions.
                        </div>
                      </form>
                      <div className="success-message w-form-done">
                        <div className="success-text">
                          Thank you! Your subscription has been received!
                        </div>
                      </div>
                      <div className="error-message w-form-fail">
                        <div className="error-text">
                          Oops! Something went wrong. Please try again later.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
