import "./App.css";
import { useEffect } from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Faq from "./Pages/Faq";
import Industries from "./Pages/Industries";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import Industry from "./Pages/Industry";
import Navbar from "./Navbar";
import Footer from "./Footer";
import pharmaceutical from "./Assets/pharmaceutical.jpg";
import food from "./Assets/food.jpg";
import construction from "./Assets/construction.jpg";
import appliances from "./Assets/appliances.jpg";
import agriculture from "./Assets/agriculture.jpg";
import beauty from "./Assets/beauty.jpg";

function App() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  });
  const industriesData = [
    {
      link: "pharmaceutical",
      header: "Pharmaceutical Solutions",
      subHeader: "Your Trusted Partner for Global Pharmaceutical Trade",
      subSubHeader: "Ensuring Access to Vital Medications",
      text: "ImportEx is your gateway to the global pharmaceutical industry. We specialize in the import and export of critical medications and healthcare supplies, ensuring their safe and efficient delivery to communities worldwide. With our expertise in navigating complex regulations and quality standards, we contribute to improving healthcare access on a global scale.",
      img: pharmaceutical,
    },
    {
      link: "food",
      header: "Global Culinary Connections",
      subHeader: "Your Source for Diverse Food Imports and Exports",
      subSubHeader: "Bringing World Flavors to Your Table",
      text: "Discover a world of taste with ImportEx's food import and export services. From gourmet specialties to everyday staples, we connect you with a rich variety of culinary delights. Our global network ensures the efficient movement of food products, satisfying the diverse tastes and preferences of consumers worldwide.",
      img: food,
    },
    {
      link: "appliances",
      header: "Household Technology Solutions",
      subHeader: "Your Partner for Importing and Exporting Appliances",
      subSubHeader: "Elevating Home Living Worldwide",
      text: "ImportEx simplifies the import and export of household appliances, offering a wide range of cutting-edge technology and everyday essentials. We ensure that homes and businesses worldwide have access to quality appliances, providing convenience, comfort, and efficiency in everyday life.",
      img: appliances,
    },
    {
      link: "construction",
      header: "Building a Better World",
      subHeader:
        "Your Reliable Source for Construction Materials and Equipment",
      subSubHeader: "Empowering Builders Across Borders",
      text: "ImportEx supports construction projects worldwide by providing a seamless supply chain for construction materials and equipment. We facilitate access to high-quality resources, helping builders and developers construct robust and sustainable structures that shape the future of communities.",
      img: construction,
    },
    {
      link: "agriculture",
      header: "Farm-to-Global Markets",
      subHeader: "Your Link to Agricultural Imports and Exports",
      subSubHeader: "Bridging the Fields and the World",
      text: "At ImportEx, we bridge the gap between agriculture and international markets. Our import and export solutions ensure the efficient movement of agricultural products, connecting farmers and agricultural businesses with global opportunities. We contribute to a steady supply of high-quality produce and agricultural goods worldwide.",
      img: agriculture,
    },
    {
      link: "beauty",
      header: "Enhancing Beauty Worldwide",
      subHeader: "Your Partner for Streamlined Beauty Product Distribution",
      subSubHeader: "Unveiling Confidence, One Product at a Time",
      text: "ImportEx streamlines the import and export of beauty products, making them accessible to individuals around the world. We facilitate the distribution of cosmetics, skincare, and personal care items, contributing to enhanced well-being and confidence for people of all backgrounds.",
      img: beauty,
    },
  ];
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/contact" element={<Contact></Contact>} />
          <Route path="/faq" element={<Faq></Faq>} />
          <Route path="/industries" element={<Industries></Industries>} />
          <Route path="/privacy" element={<Privacy></Privacy>} />
          <Route path="/termsandconditions" element={<Terms></Terms>} />
          {/* Example of dynamic industry route */}
          {industriesData.map((item) => {
            return (
              <Route
                path={`/industry/${item.link}`}
                element={<Industry data={item}></Industry>}
              />
            );
          })}
          {/* Add additional routes for each industry */}
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
