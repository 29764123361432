import React from "react";
import { useState, useEffect } from "react";
const Privacy = () => {
  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <section className="section_privacy-policy_faq">
          <div className="padding-global-3">
            <div className="container-small">
              <div className="padding-section-large no-padding-bottom">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large">
                      <div className="margin-bottom margin-small">
                        <h2>Privacy</h2>
                      </div>
                      <p className="text-size-medium">
                        Find answers to common questions about our privacy
                        policy and data usage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="privacy-policy_faq_component">
                  <div className="privacy-policy_faq_accordion">
                    <div
                      data-w-id="ef795282-4c08-f979-0d39-116b6ec1d683"
                      className="privacy-policy_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          What information does ImportEx collect from website
                          visitors?
                        </strong>
                      </div>
                    </div>
                    <div className="privacy-policy_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-18">
                          ImportEx collects only the information necessary for
                          the purpose of improving user experience and providing
                          relevant information. This may include IP addresses,
                          browser type, and page visits, but no personally
                          identifiable information is collected without your
                          explicit consent.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="privacy-policy_faq_accordion">
                    <div
                      data-w-id="ef795282-4c08-f979-0d39-116b6ec1d68d"
                      className="privacy-policy_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          How is my personal information handled by ImportEx?
                        </strong>
                      </div>
                    </div>
                    <div className="privacy-policy_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-19">
                          ImportEx values your privacy and takes data protection
                          seriously. Your personal information is securely
                          stored and used solely for the purposes you've
                          consented to, such as responding to inquiries or
                          providing services.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="privacy-policy_faq_accordion">
                    <div
                      data-w-id="ef795282-4c08-f979-0d39-116b6ec1d697"
                      className="privacy-policy_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          Does ImportEx share my personal information with third
                          parties?
                        </strong>
                      </div>
                    </div>
                    <div className="privacy-policy_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-20">
                          ImportEx does not sell or share your personal
                          information with third parties for marketing purposes.
                          However, we may share data with trusted partners and
                          service providers to fulfill specific requests or
                          improve our services, but always in accordance with
                          our Privacy Policy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="privacy-policy_faq_accordion">
                    <div
                      data-w-id="ef795282-4c08-f979-0d39-116b6ec1d6a1"
                      className="privacy-policy_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>How is my data secured by ImportEx?</strong>
                      </div>
                    </div>
                    <div className="privacy-policy_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-21">
                          ImportEx employs industry-standard security measures
                          to protect your data from unauthorized access,
                          alteration, or disclosure. We continuously monitor and
                          update our security protocols to ensure your
                          information remains safe.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="privacy-policy_faq_accordion">
                    <div
                      data-w-id="ef795282-4c08-f979-0d39-116b6ec1d6ab"
                      className="privacy-policy_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          What cookies does ImportEx use, and why?
                        </strong>
                      </div>
                    </div>
                    <div className="privacy-policy_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-22">
                          ImportEx uses cookies to enhance your browsing
                          experience. Our cookies are primarily used for
                          analytics, allowing us to understand how visitors
                          interact with our website. You can manage cookie
                          preferences through your browser settings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="margin-top margin-xxlarge ">
                  <div className="text-align-center">
                    <div className="max-width-medium align-center">
                      <div className="margin-bottom margin-xsmall">
                        <h4>Contact Us</h4>
                      </div>
                      <p className="text-size-medium">
                        Still have questions? Get in touch with us.
                      </p>
                      <div className="margin-top margin-medium">
                        <a
                          href="contact"
                          className="button is-secondary w-button"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Privacy;
