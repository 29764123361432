import React from "react";
import logo from "../src/Assets/logoDark.svg";

const Footer = () => {
  return (
    <footer className="footer7_component">
      <div className="padding-global-5">
        <div className="container-large">
          <div className="padding-vertical padding-xxlarge">
            <div className="padding-bottom padding-xxlarge">
              <div className="footer7_top-wrapper">
                <a
                  href="/index"
                  id="w-node-_38a0d19b-f46c-3636-0a6d-437e4a1138a4-4a11389e"
                  aria-current="page"
                  className="footer7_logo-link w-nav-brand w--current"
                >
                  <img src={logo} loading="lazy" alt="" />
                </a>
                <div className="w-layout-grid footer7_link-list">
                  <a href="/about" className="footer7_link">
                    About Us
                  </a>
                  <a href="/industries" className="footer7_link">
                    Industries
                  </a>
                  <a href="/faq" className="footer7_link">
                    FAQs
                  </a>
                  <a href="/contact" className="footer7_link">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="line-divider" />
            <div className="padding-top padding-medium">
              <div className="footer7_bottom-wrapper">
                <div className="footer7_credit-text">
                  © 2023 ImportEx. All rights reserved.
                </div>
                <div className="w-layout-grid footer7_legal-list">
                  <a href="/privacy" className="footer7_legal-link">
                    Privacy Policy
                  </a>
                  <a href="/termsandconditions" className="footer7_legal-link">
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
