import React from "react";
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
// import { useDispatch } from "react-redux";
// import { database } from "../Server";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Create onChange handlers for each input
  const handleNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handleMessageChange = (e) => {
    setFormData({ ...formData, message: e.target.value });
  };

  // const dispatch = useDispatch();
  // Handle form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const options = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "aplication/json",
  //     },
  //     body: JSON.stringify(formData),
  //   };
  //   const res = fetch(
  //     "https://importex-edae7-default-rtdb.firebaseio.com/ContactMessage.json",
  //     options
  //   );

  //   if (res) {
  //     console.log(res);
  //     alert("message sent");
  //   } else {
  //     alert("error");
  //   }
  //   // You can access the form data in formData.name, formData.email, formData.message
  //   console.log(formData);
  //   // Perform any further actions (e.g., send data to a server)
  // };
  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const [error, setError] = useState();

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the formData, e.g., send it to an API

    const serviceId = "service_w0vllof";
    const templateId = "template_asan69r";
    const publicKey = "ebYpEQznrWiCeFfLv";

    emailjs
      .send(serviceId, templateId, formData, publicKey)
      .then((response) => {
        console.log("Form data submitted:", formData);
        setFormData({
          name: "",
          email: "",
          message: "",
          agreeToTerms: false,
        });
      })
      .catch((error) => {
        console.log("Error sending email: ", error);
      });
  };
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <section className="section_contact-us_contact-form">
          <div className="padding-global-3">
            <div className="container-small">
              <div className="padding-section-large">
                <div className="margin-bottom margin-large">
                  <div className="text-align-center">
                    <div className="max-width-large align-center">
                      <div className="margin-bottom margin-small">
                        <h2>Get in Touch</h2>
                      </div>
                      <p className="text-size-medium text-color-black">
                        Have a question or feedback? We'd love to hear from you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="max-width-medium align-center">
                  <div className="contact-us_contact-form_component w-form">
                    <form
                      id="wf-form-Contact-1-Form"
                      className="contact-us_contact-form_form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-field-wrapper">
                        <label htmlFor="Contact-1-Name" className="field-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-input w-input"
                          maxLength={256}
                          placeholder=""
                          required=""
                          value={formData.name}
                          onChange={handleNameChange}
                        />
                      </div>
                      <div className="form-field-wrapper">
                        <label
                          htmlFor="Contact-1-Email"
                          className="field-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-input w-input"
                          maxLength={256}
                          placeholder=""
                          required=""
                          value={formData.email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="form-field-wrapper">
                        <label className="field-label">Message</label>
                        <textarea
                          placeholder="Type your message..."
                          maxLength={5000}
                          data-name="Contact 1 Message"
                          required=""
                          className="form-input is-text-area w-input"
                          value={formData.message}
                          onChange={handleMessageChange}
                        />
                      </div>
                      <input
                        type="submit"
                        defaultValue="Submit"
                        data-wait="Please wait..."
                        id="w-node-f1899a65-58ff-e235-d163-0a4157322995-9505347e"
                        className="button"
                      />
                    </form>
                    {/* <div className="success-message w-form-done">
                      <div className="success-text">
                        Thank you! We have received your submission.
                      </div>
                    </div>
                    <div className="error-message w-form-fail">
                      <div className="error-text">
                        Oops! Something went wrong. Please try again.
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_contact-us_locations">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large align-center">
                      <div className="margin-bottom margin-small">
                        <h2>Locations</h2>
                      </div>
                      <p className="text-size-medium">
                        Find our office locations and contact information below.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid contact-us_locations_component">
                  <div className="contact-us_locations_location">
                    <div className="margin-bottom margin-medium">
                      <div className="contact-us_locations_map-wrapper">
                        <div
                          className="contact-us_locations_map w-widget w-widget-map"
                          data-widget-style="roadmap"
                          data-widget-latlng=""
                          data-enable-scroll="true"
                          role=""
                          title=""
                          data-enable-touch="true"
                          data-widget-zoom={12}
                          data-widget-tooltip=""
                        />
                      </div>
                    </div>
                    <div className="margin-bottom margin-xsmall">
                      <h3 className="heading-style-h4">Sydney</h3>
                    </div>
                    <p className="text-color-black">
                      French AVE,Bankstown, NSW, 2200
                    </p>
                    <div className="margin-top margin-small">
                      <div className="button-group is-center">
                        <a
                          href="#"
                          className="button is-link is-icon w-inline-block"
                        >
                          <div>Get Directions &gt;</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="contact-us_locations_location">
                    <div className="margin-bottom margin-medium">
                      <div className="contact-us_locations_map-wrapper">
                        <div
                          className="contact-us_locations_map w-widget w-widget-map"
                          data-widget-style="roadmap"
                          data-widget-latlng=""
                          data-enable-scroll="true"
                          role=""
                          title=""
                          data-enable-touch="true"
                          data-widget-zoom={12}
                          data-widget-tooltip=""
                        />
                      </div>
                    </div>
                    <div className="margin-bottom margin-xsmall">
                      <h3 className="heading-style-h4">Lebanon</h3>
                    </div>
                    <p className="text-color-black">Halat, Byblos 1410</p>
                    <div className="margin-top margin-small">
                      <div className="button-group is-center">
                        <a
                          href="#"
                          className="button is-link is-icon w-inline-block"
                        >
                          <div>Get Directions &gt;</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default Contact;
