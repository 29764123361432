import React from "react";
import { useState, useEffect } from "react";
const Faq = () => {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  });

  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <section className="section_faq_faq">
          <div className="padding-global-3">
            <div className="container-small">
              <div className="padding-section-large no-padding-bottom">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large">
                      <div className="margin-bottom margin-small">
                        <h2>FAQs</h2>
                      </div>
                      <p className="text-size-medium text-color-black">
                        Find answers to the most common questions about
                        ImportEx's services, shipping, and procedures.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="faq_faq_component">
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="87fd189c-57a9-ab78-bf87-2b88eb36c33b"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          What industries does ImportEx serve for import and
                          export?
                        </strong>
                      </div>
                    </div>
                    <div
                      //   style={{ width: "100%", height: 0 }}
                      className="faq_faq_answer"
                    >
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-13">
                          ImportEx offers import and export services for a wide
                          range of industries, including pharmaceuticals, food,
                          appliances, construction, agriculture, beauty, and
                          many more. We have extensive expertise in connecting
                          businesses from various sectors with global markets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="87fd189c-57a9-ab78-bf87-2b88eb36c345"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>What regions does ImportEx operate in?</strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-14">
                          ImportEx operates globally, facilitating import and
                          export transactions to and from Australia. We have a
                          broad network of international partners, allowing us
                          to serve clients across continents.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="87fd189c-57a9-ab78-bf87-2b88eb36c34f"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          How can ImportEx help my business with international
                          trade?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-15">
                          ImportEx simplifies international trade for your
                          business. We handle everything from logistics and
                          customs clearance to compliance and quality checks,
                          ensuring a smooth and efficient import/export process.
                          Our expertise saves you time and resources while
                          expanding your market reach.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="87fd189c-57a9-ab78-bf87-2b88eb36c359"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          What sets ImportEx apart from other import/export
                          companies?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-16">
                          ImportEx stands out due to our industry diversity and
                          extensive connections. We're not limited to specific
                          sectors, and our network spans the globe. This allows
                          us to provide tailored solutions for each client's
                          unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="87fd189c-57a9-ab78-bf87-2b88eb36c363"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          How can I get started with ImportEx's services?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-17">
                          To start benefiting from ImportEx's import and export
                          services, simply contact our team. We'll discuss your
                          specific requirements, provide you with a customized
                          solution, and guide you through the process, making
                          international trade hassle-free for your business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="0ba8b450-ea9d-19f1-012e-e182e3edf586"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          Does ImportEx handle all aspects of international
                          trade, including documentation?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-17">
                          Yes, ImportEx provides comprehensive services,
                          including handling documentation, customs procedures,
                          shipping logistics, and compliance matters. We ensure
                          that all necessary paperwork is in order for a smooth
                          and compliant import/export process.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="17000e39-b0e4-15b0-00ce-da52057c09e4"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          Can ImportEx assist with both large and small-scale
                          import/export operations?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-17">
                          Absolutely! ImportEx caters to businesses of all
                          sizes. Whether you're a small enterprise or a large
                          corporation, our flexible solutions are tailored to
                          meet your specific needs and budget.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="eff983bd-d87d-a6e3-3a13-e1f3a881af8c"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          What is ImportEx's commitment to sustainability and
                          responsible trade?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-17">
                          ImportEx is committed to responsible trade practices.
                          We work to minimize our environmental impact and
                          support sustainable sourcing and manufacturing
                          whenever possible. We believe in ethical and
                          eco-friendly trade solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq_faq_accordion">
                    <div
                      data-w-id="b489dec4-e69b-5213-d79d-ed31a348b409"
                      className="faq_faq_question"
                    >
                      <div className="text-size-medium text-weight-bold">
                        <strong>
                          Are there any additional services or resources that
                          ImportEx provides to clients?
                        </strong>
                      </div>
                    </div>
                    <div className="faq_faq_answer">
                      <div className="margin-bottom margin-small">
                        <p className="paragraph-17">
                          In addition to our import and export services,
                          ImportEx offers market research, trade consulting, and
                          guidance on navigating international regulations.
                          We're here to support your business at every stage of
                          its international expansion.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="margin-top margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-medium align-center">
                      <div className="margin-bottom margin-xsmall">
                        <h4>Still have questions?</h4>
                      </div>
                      <p className="text-size-medium">
                        Contact our support team for assistance.
                      </p>
                      <div className="margin-top margin-medium">
                        <a
                          href="contact"
                          className="button is-secondary w-button"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Faq;
