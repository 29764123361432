import React from "react";
import sourcing from "../Assets/sourcing.svg";
import shipment from "../Assets/shipment.svg";
import marketing from "../Assets/marketing.svg";
import cargo from "../Assets/cargo.jpg";
import ship from "../Assets/ship port trade.jpg";
import { useState, useEffect } from "react";

const About = () => {
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_about-us_header-2">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <h1 className="text-color-white">Discover Our Story</h1>
                  </div>
                  <p className="text-size-medium text-color-white">
                    Learn more about ImportEx and our mission to connect
                    businesses globally.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="about-us_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={cargo}
              loading="eager"
              alt=""
              className="about-us_header_background-image"
            />
          </div>
        </header>
        <section className="section_about-us_about">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid about-us_about_component-2">
                  <div className="about-us_about_content-left">
                    <h3>
                      ImportEx: A History of Excellence in Importing and
                      Exporting Goods
                    </h3>
                  </div>
                  <div className="about-us_about_content-right">
                    <p className="text-size-medium text-color-black">
                      ImportEx is a leading company in the import and export
                      industry, specializing in the transportation and
                      distribution of goods to and from Australia. With a strong
                      commitment to quality and customer satisfaction, our
                      mission is to provide efficient and reliable import and
                      export services that meet the needs of our clients. We
                      envision a world where global trade is seamless and
                      accessible, fostering economic growth and cultural
                      exchange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_layout238">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="layout238_component">
                  <div className="margin-bottom margin-xxlarge">
                    <div className="text-align-center">
                      <div className="max-width-large">
                        <h3 className="heading">
                          Discover the Easy Process of Importing and Exporting
                          with ImportEx
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid layout238_list">
                    <div className="layout238_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={sourcing}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h5">Sourcing</h3>
                      </div>
                      <p className="paragraph-3">
                        Our global supplier network enables us to provide a
                        diverse product range. We maintain stringent quality
                        standards through close collaboration with suppliers and
                        rigorous pre-shipment quality checks.
                      </p>
                    </div>
                    <div className="layout238_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={marketing}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h5">Marketing</h3>
                      </div>
                      <p className="paragraph">
                        We help our clients discover new international market
                        opportunities by leveraging our experts' knowledge of
                        trade regulations, tariffs, and a wide network of
                        international contacts. This assists our clients in
                        uncovering untapped markets for their products.
                      </p>
                    </div>
                    <div className="layout238_item">
                      <div className="margin-bottom margin-small">
                        <img
                          src={shipment}
                          loading="lazy"
                          alt=""
                          className="icon-1x1-medium"
                        />
                      </div>
                      <div className="margin-bottom margin-small">
                        <h3 className="heading-style-h5">Shipment</h3>
                      </div>
                      <p className="paragraph-2">
                        We simplify the shipping process for our customers by
                        handling everything, including transportation, customs
                        clearance, and compliance, alleviating the complexities
                        of international trade.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_service_feature">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid service_feature_component">
                  <div className="service_feature_content">
                    <div className="margin-bottom margin-small">
                      <h3>Our Expertise</h3>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium text-color-black">
                        With over 20 years of experience in all major products
                        industries, ImportEx enables overseas importers located
                        anywhere in the world to find and get in touch with
                        qualified, high quality producers and exporters.
                      </p>
                    </div>
                    <div className="w-layout-grid service_feature_item-list">
                      <div className="service_feature_text-wrapper">
                        <div className="margin-bottom margin-xsmall">
                          <h6>Efficient Import Solutions</h6>
                        </div>
                        <p className="paragraph-7">
                          Our team of experienced professionals has a deep
                          understanding of the import and export industry. We
                          work closely with our clients to understand their
                          unique needs and develop customized solutions to help
                          them succeed in the global marketplace.
                        </p>
                      </div>
                      <div className="service_feature_text-wrapper">
                        <div className="margin-bottom margin-xsmall">
                          <h6>Global Export Services</h6>
                        </div>
                        <p className="paragraph-8">
                          We are committed to building long-term relationships
                          with our customers, and strive to exceed their
                          expectations. With our extensive network of suppliers
                          and partners, we are able to offer competitive prices
                          and timely delivery on all of our products.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="service_feature_image-wrapper">
                    <img
                      src={ship}
                      loading="lazy"
                      alt=""
                      className="service_feature_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_about-us_cta">
          <div className="padding-global-3">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid about-us_cta_component-2">
                  <div className="about-us_cta_content-left">
                    <h2 className="text-color-white">
                      Customized Service Solutions for You
                    </h2>
                  </div>
                  <div className="about-us_cta_content-right">
                    <p className="text-size-medium text-color-white">
                      At ImportEx, we understand that every business has unique
                      service needs. Contact us today to discuss how we can
                      tailor our services to meet your specific requirements.
                    </p>
                    <div className="margin-top margin-medium">
                      <div className="button-group">
                        <a href="/industries" className="button w-button">
                          Industries
                        </a>
                        <a
                          href="/contact"
                          className="button is-link is-icon w-inline-block"
                        >
                          <div className="text-block-2">Contact Us</div>
                          <div className="icon-embed-xxsmall w-embed">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 3L11 8L6 13"
                                stroke="CurrentColor"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
