import React from "react";
import { useState, useEffect } from "react";
const Terms = () => {
  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <section className="section_terms-and-services_faq">
          <div className="padding-global-5">
            <div className="container-small">
              <div className="padding-section-large no-padding-bottom">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large-2">
                      <div className="margin-bottom margin-small">
                        <h2>
                          <strong>ImportEx Terms and Conditions</strong>
                        </h2>
                      </div>
                      <p className="text-size-medium-2">
                        Welcome to ImportEx! These Terms and Conditions
                        ("Terms") govern your use of the ImportEx website and
                        services (collectively, the "Service") provided by
                        ImportEx.By using our Service, you agree to comply with
                        and be bound by these Terms. If you do not agree to
                        these Terms, please do not use our Service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="terms-and-services_faq_component">
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Use of the Service</strong>
                      </div>
                    </div>
                    <p className="paragraph-23">
                      a. Eligibility: You must be at least 18 years old to use
                      our Service.
                      <br />
                      b. Account: You are responsible for maintaining the
                      confidentiality of your account and password. You agree to
                      accept responsibility for all activities that occur under
                      your account.
                      <br />
                      c. Lawful Use: You agree to use our Service in a lawful
                      manner and not for any illegal or unauthorized purpose.
                    </p>
                  </div>
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Content</strong>
                      </div>
                    </div>
                    <p className="paragraph-24">
                      a. User-Generated Content: Our Service may allow you to
                      post, share, or otherwise provide content. You are solely
                      responsible for the content you submit and its accuracy.
                      <br />
                      b. Licensing: By posting content on our Service, you grant
                      ImportEx a non-exclusive, worldwide, royalty-free license
                      to use, reproduce, modify, adapt, publish, translate,
                      distribute, and display the content.
                    </p>
                  </div>
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Privacy</strong>
                      </div>
                    </div>
                    <p className="paragraph-25">
                      a. Your privacy is important to us. Our Privacy Policy
                      explains how we collect, use, and protect your personal
                      information. By using our Service, you consent to our
                      Privacy Policy.
                    </p>
                  </div>
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Limitation of Liability</strong>
                      </div>
                    </div>
                    <p className="paragraph-26">
                      a. To the fullest extent permitted by applicable law,
                      ImportEx and its affiliates shall not be liable for any
                      indirect, incidental, special, consequential, or punitive
                      damages, or any loss of profits or revenues, whether
                      incurred directly or indirectly.
                    </p>
                  </div>
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Termination</strong>
                      </div>
                    </div>
                    <p className="paragraph-24">
                      a. We reserve the right to terminate or suspend your
                      account and access to our Service at our sole discretion,
                      without prior notice, for any reason, including a breach
                      of these Terms.
                    </p>
                  </div>
                  <div className="terms-and-services_faq_item">
                    <div className="margin-bottom margin-xsmall">
                      <div className="text-size-medium-2 text-weight-bold">
                        <strong>Contact Information</strong>
                      </div>
                    </div>
                    <p className="paragraph-24">
                      If you have any questions about these Terms and
                      Conditions, please contact us at tonys@importex.io.By
                      using ImportEx's Service, you acknowledge that you have
                      read, understood, and agreed to these Terms and
                      Conditions.
                    </p>
                  </div>
                </div>
                <div className="margin-top margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-medium align-center">
                      <div className="margin-bottom margin-xsmall">
                        <h4>Still have questions?</h4>
                      </div>
                      <p className="text-size-medium-2">
                        Contact our customer support for assistance.
                      </p>
                      <div className="margin-top margin-medium">
                        <a
                          href="contact"
                          className="button-2 is-secondary w-button"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Terms;
