import React from "react";
import header from "../Assets/airport shipment.jpg";
import { useState, useEffect } from "react";
const Industry = ({ data }) => {
  const [navbarHeight, setNavbarHeight] = useState();
  useEffect(() => {
    const navbar = document.getElementsByClassName("navbar12_component")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  return (
    <div className="page-wrapper" style={{ paddingTop: navbarHeight }}>
      <main className="main-wrapper">
        <header className="section_service_header">
          <div className="padding-global-5">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="max-width-large-2">
                  <div className="margin-bottom margin-small">
                    <h1 className="text-color-white">
                      <strong>{data.header}</strong>
                    </h1>
                  </div>
                  <p className="text-size-medium-8 text-color-white">
                    <em>{data.subHeader}</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service_header_background-image-wrapper">
            <div className="image-overlay-layer" />
            <img
              src={header}
              loading="eager"
              alt=""
              className="service_header_background-image"
            />
          </div>
        </header>
        <section className="section_service_feature">
          <div className="padding-global-5">
            <div className="container-large">
              <div className="padding-section-large">
                <div className="w-layout-grid service_feature_component">
                  <div className="service_feature_content">
                    <div className="margin-bottom margin-small">
                      <h3>
                        <strong>{data.subSubHeader}</strong>
                      </h3>
                    </div>
                    <p className="text-size-medium-8">{data.text}</p>
                  </div>
                  <div className="service_feature_image-wrapper">
                    <img
                      src={data.img}
                      loading="lazy"
                      alt=""
                      className="service_feature_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_service_cta-2">
          <div className="padding-global-5">
            <div className="container-small">
              <div className="padding-section-large no-padding-bottom">
                <div className="text-align-center">
                  <div className="margin-bottom margin-small">
                    <h2>ImportEx: Your Import-Export Experts</h2>
                  </div>
                  <p className="text-size-medium-8">
                    Contact ImportEx today for more information or a quote on
                    our import-export services.
                  </p>
                  <div className="margin-top margin-medium">
                    <div className="button-group is-center">
                      <a
                        href="/contact"
                        className="button-2 w-button w-button-black-hover"
                      >
                        Contact
                      </a>
                      <a
                        href="/about"
                        className="button-2 is-secondary w-button-black-hover-secondary"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Industry;
